var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',{staticClass:"auth-inner"},[_c('router-link',{staticClass:"brand-logo d-flex align-center",attrs:{"to":"/"}},[_c('v-img',{staticClass:"me-3",attrs:{"src":_vm.appLogo,"max-width":"150px","alt":"logo","contain":""}})],1),_c('v-row',{staticClass:"auth-row ma-0"},[_c('v-col',{staticClass:"\n                    d-none d-lg-block\n                    position-relative\n                    overflow-hidden\n                    pa-0\n                    login-side-col\n                ",attrs:{"lg":"8"}}),_c('v-col',{staticClass:"d-flex align-center auth-bg pa-10 pb-0",attrs:{"lg":"4"}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"\n                                        text-2xl\n                                        font-weight-semibold\n                                        text--primary\n                                        my-2\n                                    "},[_vm._v(" Your learning adventure starts here 🚀 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Welcome to the WIN curriculum ")])]),_c('v-card-text',[_c('v-form',{ref:"registerForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mb-6",attrs:{"outlined":"","rules":[_vm.validators.required],"label":"Name","placeholder":"Name","hide-details":"auto"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"mb-6",attrs:{"outlined":"","error-messages":_vm.errorMessages.email,"rules":[
                                            _vm.validators.required,
                                            _vm.validators.emailValidator ],"label":"Email","placeholder":"Email","hide-details":"auto"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","type":_vm.isPasswordVisible
                                                ? 'text'
                                                : 'password',"label":"Password","error-messages":_vm.errorMessages.password,"placeholder":"Password","rules":[
                                            _vm.validators.required,
                                            _vm.validators.passwordValidator ],"append-icon":_vm.isPasswordVisible
                                                ? _vm.icons.mdiEyeOffOutline
                                                : _vm.icons.mdiEyeOutline,"hide-details":"auto"},on:{"click:append":function($event){_vm.isPasswordVisible =
                                                !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"\n                                                    d-flex\n                                                    align-center\n                                                    flex-wrap\n                                                "},[_c('span',{staticClass:"me-2"},[_vm._v("I agree to")]),_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("privacy policy & terms")])])]},proxy:true}])}),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","color":"primary","type":"submit"}},[_vm._v(" Sign Up ")])],1)],1),_c('v-card-text',{staticClass:"\n                                    d-flex\n                                    align-center\n                                    justify-center\n                                    flex-wrap\n                                    mt-2\n                                "},[_c('p',{staticClass:"mb-0 me-2"},[_vm._v(" Already have an account? ")]),_c('router-link',{attrs:{"to":{ name: 'auth-login' }}},[_vm._v(" Sign in instead ")])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }